import Link from 'components/atoms/Link';
import StaticPage from 'components/templates/StaticPage';
import { redirectToLogout } from 'providers/authorization/AuthProvider';

const Unsubscribed = () => {
    return (
        <StaticPage>
            <h1>Connect requires a&nbsp;subscription</h1>
            <p>
                If you have signed up for trial or have just purchased a subscription, please give
                us a few minutes to set up your account and try again.
            </p>
            {process.env.BUY_CONNECT && (
                <p>
                    Click{' '}
                    <Link
                        onClick={() => window.location.replace(process.env.BUY_CONNECT as string)}
                    >
                        here
                    </Link>{' '}
                    for more information.
                </p>
            )}
            <p>
                If you think it was a mistake, please <Link onClick={redirectToLogout}>logout</Link>
                .
            </p>
            <p>Please make sure you have a valid subscription or login with a different account.</p>
        </StaticPage>
    );
};

export default Unsubscribed;
